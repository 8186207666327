<template>
	<div class="row">
      <div :class="dadosUsuario.usuarioPermissoes.includes('Visualizar dados analíticos') ? 'col-lg-9' : 'col-12'">
			<div class="card mb-2 homeBg">
				<div class="d-flex align-items-center p-4">
					<div class="align-items-center justify-content-center me-2">
						<img src="@/assets/images/logo-icon.png" alt="Mooli Logo" class="m-2 me-4" width="60">
					</div>
					<h2 class="font-30 mt-0 mb-0">CAROLINA<span class="d-none d-sm-inline"><small class="mx-2"><i class="fas fa-horizontal-rule font-17"></i></small>GERENCIAL</span></h2>
				</div>
			</div>

			<div class="card mb-2" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar dados analíticos')">
				<div class="card-body px-12 py-2">
					<div class="row mb-2 align-items-center">
						<div class="col">
							<h6 class="font-15 mb-0"><i class="fas fa-angle-right font-15 color-theme me-1"></i> Calendário analítico</h6>
						</div>
						<div class="col d-none d-sm-block pe-12">
							<h6 class="font-14 mb-0 text-end text-capitalize"><i class="far fa-calendar-check color-theme me-1"></i> {{ calendario.selecionado.mesString }}</h6>
						</div>
						<div class="wpx-125 pe-0">
							<date-picker v-model="calendario.data" type="month" format="MM/YYYY" :editable="false" :clearable="false" @input="buscarResultados(null)"></date-picker>
						</div>
					</div>
					<div class="row row-cols-7">
						<div v-for="(diaSemana, index) in diasSemana" :key="index" class="card bg-transparent rounded-0" :class="index % 7 != 6 ? 'border-end-0' : ''">
							<div class="card-body px-0 py-1 p-sm-2 weight-500">{{ diaSemana }}</div>
						</div>
					</div>
					<div class="row row-cols-7">
						<div v-for="(dia, index) in calendario.dias" :key="index" class="col px-0">
							<div class="card bg-transparent rounded-0 border-top-0 hpx-70" :class="index % 7 != 6 ? 'border-end-0' : ''">
								<div class="card-body p-2 cursor-pointer" :class="calendario.selecionado.data == dia.date ? 'box-inset-theme' : ''" @click="buscarResultados(dia.day, dia.month, dia.year)">
									<span class="d-block font-15 w-max-content" :class="[dia.isSelectedMonth ? '' : 'text-secondary opacity-50', calendario.selecionado.data == dia.date ? 'color-theme box-inset-theme px-2' : '']">{{ dia.day }}</span>
									<span class="d-block font-12" :class="calendario.selecionado.data == dia.date ? '' : dia.isSelectedMonth ? 'opacity-75' : 'text-secondary opacity-50'">
										<span v-if="dia.clientesTeste > 0">
											<i class="fal fa-badge-percent font-11 me-1" :class="calendario.selecionado.data == dia.date ? 'color-theme' : ''"></i>
											<span class="d-none d-xxl-inline">{{ dia.clientesTeste }}</span>
										</span>
										<small v-if="dia.clientesTeste > 0 && (dia.faturasPagas > 0 || dia.faturasPendentes > 0)" class="mx-1 d-none d-xxl-inline">|</small>
										<span v-if="dia.faturasPagas > 0">
											<i class="fal fa-file-check font-11 me-1" :class="calendario.selecionado.data == dia.date ? 'color-theme' : ''"></i>
											<span class="d-none d-xxl-inline">{{ dia.faturasPagas }}</span>
										</span>
										<small v-if="dia.faturasPagas > 0 && dia.faturasPendentes > 0" class="mx-1 d-none d-xxl-inline">|</small>
										<span v-if="dia.faturasPendentes > 0">
											<i class="fal fa-file-times font-11 me-1" :class="calendario.selecionado.data == dia.date ? 'color-theme' : ''"></i>
											<span class="d-none d-xxl-inline">{{ dia.faturasPendentes }}</span>
										</span>
									</span>									
								</div>
							</div>
						</div>	
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-3" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar dados analíticos')">
			<div class="card mb-2">
				<div class="card-body p-12">
					<div class="row align-items-center">
						<div class="col-12 mt-1">
							<h6 class="font-15 mb-12"><i class="fas fa-angle-right color-theme me-1"></i> Dados gerais</h6>
						</div>
						<div class="col-12 px-0">
                     <div class="row">
                        <div class="col-sm-4 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-user font-35 color-theme ms-2 me-4 d-sm-none d-md-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1">{{ calendario.totalClientes }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Clientes</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-4 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1 cursor-pointer" @click="visualizarContas(null)">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-badge-percent font-35 color-theme ms-1 me-4 d-sm-none d-md-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1">{{ calendario.totalClientesTeste }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Clientes em teste</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-4 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-store font-30 color-theme ms-1 me-4 d-sm-none d-md-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1">{{ calendario.totalLojas }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Lojas cadastradas</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
					</div>
				</div>
			</div>
			<div class="card mb-2">
				<div class="card-body p-12">
					<div class="row align-items-center">
						<div class="col-12 mt-1">
							<h6 class="font-15 mb-12">
                        <span><i class="fas fa-angle-right color-theme me-1"></i> Resultados</span>
                        <span class="float-end font-14">
                           <i class="far fa-calendar-day color-theme me-1 me-xl-2"></i>
                           <span v-if="calendario.selecionado.dia">{{ String(calendario.selecionado.dia).padStart(2, '0') }}/</span>
                           <span>{{ String(calendario.selecionado.mes + 1).padStart(2, '0') }}/{{ calendario.selecionado.ano }}</span>
                        </span>
                     </h6>
						</div>
						<div class="col-12 px-0">
                     <div class="row">
                        <div class="col-sm-6 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1 cursor-pointer" @click="visualizarContas(calendario.selecionado)">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-badge-percent font-35 color-theme ms-1 me-4 d-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1">{{ calendario.quantidadeTestes }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Testes encerrando</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-money-check-edit font-30 color-theme ms-1 me-4 d-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1"><small>R$ </small>{{ parseFloat(calendario.valorReceber).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Valor a receber</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-wallet font-35 color-theme ms-1 me-4 d-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1"><small>R$ </small>{{ parseFloat(calendario.valorRecebido).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Valor recebido ({{ parseFloat(calendario.valorReceber) > 0 ? ((parseFloat(calendario.valorRecebido) / parseFloat(calendario.valorReceber)) * 100).toFixed(2) : parseFloat(calendario.valorRecebido) > 0 ? 0 : 100 }}%)</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-lg-12">
                           <div class="card bg-transparent rounded-1 mb-1 cursor-pointer" data-bs-toggle="modal" data-bs-target="#modalFaturas">
                              <div class="card-body d-flex align-items-center p-12">
                                 <i class="fal fa-barcode font-35 color-theme ms-1 me-4 d-block d-lg-none d-xl-block"></i>
                                 <div>
                                    <h1 class="limitador-1 font-17 mb-1">{{ calendario.faturas.length }}</h1>
                                    <p class="limitador-1 font-13 mb-0">Fatura(s)</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalFaturas -->
      <div class="modal fade" id="modalFaturas" tabindex="-1" aria-labelledby="modalFaturasLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalFaturasLabel">
							<span>Faturas <small class="mx-2">|</small> <i class="far fa-calendar-day font-15 me-2"></i></span>
							<span v-if="calendario.selecionado.dia">{{ String(calendario.selecionado.dia).padStart(2, '0') }}/</span>
							<span>{{ String(calendario.selecionado.mes + 1).padStart(2, '0') }}/{{ calendario.selecionado.ano }}</span>
						</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<fatura v-for="(fatura, index) in calendario.faturas" :key="index" :fatura="fatura" :index="index" />

						<div class="col-12 mb-1" v-if="calendario.faturas.length == 0">
							<div class="card mb-0">
								<div class="card-body py-5 text-center">
									<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
									<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma fatura encontrada</h4>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- modalContas -->
      <div class="modal fade" id="modalContas" tabindex="-1" aria-labelledby="modalContasLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalContasLabel">Clientes</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<cliente v-for="(cliente, index) in calendario.clientes" :key="index" :cliente="cliente" :index="index" />

						<div class="col-12 mb-1" v-if="calendario.clientes.length == 0">
							<div class="card mb-0">
								<div class="card-body py-5 text-center">
									<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
									<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum cliente encontrado</h4>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import fatura from '@/components/paginaInicial/Fatura.vue'
import cliente from '@/components/paginaInicial/Cliente.vue'

export default {
	name: 'Página_Inicial',
	data: function () {
		return {
			diasSemana: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
			calendario: {
				'data': new Date(new Date().setHours(0)), 'dias': [], 'selecionado': {'dia': null, 'mes': null, 'ano': null, 'mesString': null, 'data': null}, 
            'totalClientes': 0, 'totalClientesTeste': 0, 'totalLojas': 0, 'quantidadeTestes': 0, 'valorReceber': 0, 'valorRecebido': 0, 'faturas': [], 'clientes': []
			}
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         tokenSessao: state => state.tokenSessao,
         urlRest: state => state.urlRest
      })
   },
	components: {
		fatura, cliente
	},
	methods: {
		buscarResultadoGeral: function () {
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/searchResultadoGeral'
				
			}).then((response) => {
				this.calendario.totalLojas = response.data.totalLojas
				this.calendario.totalClientes = response.data.totalClientes
				this.calendario.totalClientesTeste = response.data.totalClientesTeste

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			});
		},
		buscarResultados: function (dia, mes, ano) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			let data = {'dia': dia, 'mes': mes, 'ano': ano}

			if (!dia && !mes && !ano) {
				data = {'dia': this.calendario.data.getDate(), 'mes': this.calendario.data.getMonth(), 'ano': this.calendario.data.getFullYear()}
			} else {
				this.calendario.data = new Date(ano, mes, dia || 1)
			}
         
         this.calendario.selecionado = {
            'dia': !dia || data.dia == this.calendario.selecionado.dia ? null : data.dia, 
            'mes': data.mes, 
            'ano': data.ano, 
            'mesString': new Date(data.ano, data.mes, data.dia || 1).toLocaleString('pt-br', { month: 'long' }),
            'data': (data.ano +'-'+ String(data.mes + 1).padStart(2, '0') +'-'+ String(!dia || data.dia == this.calendario.selecionado.dia ? null : data.dia).padStart(2, '0'))
         }

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/searchResultados',
				params: {
					dia: this.calendario.selecionado.dia, 
               mes: this.calendario.selecionado.mes + 1, 
               ano: this.calendario.selecionado.ano
				}
			}).then((response) => {
				this.calendario.quantidadeTestes = response.data.quantidadeTestes
				this.calendario.valorRecebido = response.data.valorRecebido
				this.calendario.valorReceber = response.data.valorReceber
				this.calendario.faturas = response.data.faturas
				this.calendario.dias = response.data.calendario

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		visualizarContas: function (data) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getContasTeste',
				params: {
					dia: data ? data.dia : null, 
               mes: data ? data.mes + 1 : null, 
               ano: data ? data.ano : null
				}
			}).then((response) => {
				$("#modalContas").modal("show")
				this.calendario.clientes = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		this.$axios.interceptors.request.use((config) => {
			$('#search-nav .dropdown-toggle').dropdown('hide');

			if (this.tokenSessao != null && this.dadosUsuario != null) {
				config.headers.Authorization = 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.usuarioEmail;
			}

			return config;
		});

		if (this.dadosUsuario.usuarioPermissoes.includes('Visualizar dados analíticos')) {
			this.buscarResultadoGeral()
			this.buscarResultados(null, new Date().getMonth(), new Date().getFullYear());
		}
	}
}

</script>

<style scoped>

.row-cols-7 > * {
	flex: 0 0 auto;
	width: calc(100% / 7);
}

.box-inset-theme {
	-webkit-box-shadow: inset 0px 0px 0px 1px var(--color-theme);
	-moz-box-shadow: inset 0px 0px 0px 1px var(--color-theme);
	box-shadow: inset 0px 0px 0px 1px var(--color-theme);
}

</style>