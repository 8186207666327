<template>
   <div class="col-12 mb-1" ref="cliente">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ cliente.nomeUsuario || 'Desconhecido' }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ cliente.id }}</span>
                           <span class="ms-3"><i class="far fa-envelope font-9 me-1"></i>{{ cliente.emailUsuario || 'Sem email' }}</span>
                        </p>
                     </div>
                     <div class="col d-none d-sm-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-phone-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Telefone:</strong>
                           <span> {{ cliente.telefoneUsuario || 'Sem telefone' }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-puzzle-piece color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Módulo:</strong>
                           <span> {{ cliente.modulo || 'Sem módulo' }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-150 font-14 text-center mx-auto mx-sm-0" v-if="cliente.dataLimiteTeste">
                  <span class="limitador-1">{{ cliente.dataLimiteTeste }}</span>
                  <small class="d-block font-11"><i class="far fa-calendar font-10 color-theme me-1"></i> Data limite teste</small>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Cliente',
   props: ['cliente', 'index']
}

</script>